<template>
  <div v-if="shops">
    {{ checkRegister }}
    <CRow class="d-flex">
      <CCol md="12">
        <CCard border-color="light" class="shadow-sm">
          <CCardBody>
            <h1 class="text-center mt-4">
              <CImg src="../../../../logo-ab.png" width="25%" />
            </h1>

            <h4 class="font-weight-normal text-dark text-center mt-3">
              {{ $t('welcomeMessage') }} Silom CRM
            </h4>
            <hr />

            <CRow class="d-flex justify-content-center mt-4 ml-2 mr-2">
              <CCol md="3" class="text-center mb-2">
                <router-link :to="'/loyalty-point'" class="underline">
                  <CCardBody style="border-radius:15px" color="white" border-color="greyborder" class="box">
                    <CImg src="../../../img/reward/loyalty-point.png" width="100px" />

                    <h5 class="font-weight-normal text-dark mt-4">{{ $t('member.loyaltyPoint') }}</h5>
                  </CCardBody>
                </router-link>
              </CCol>
              <CCol md="3" class="text-center mb-2">
                <router-link :to="'/rewardcard'" class="underline">
                  <CCardBody style="border-radius:15px" color="white" border-color="greyborder" class="box">
                    <CImg src="../../../img/reward/reward-card.png" width="100px" />

                    <h5 class="font-weight-normal text-dark mt-4">
                      {{ $t('loyaltyCard') }}
                    </h5>
                  </CCardBody>
                </router-link>
              </CCol>
              <CCol md="3" class="text-center mb-2">
                <router-link :to="'/voucher'" class="underline">
                  <CCardBody style="border-radius:15px" color="white" border-color="greyborder" class="box">
                    <CImg src="../../../img/reward/voucher.png" width="100px" />

                    <h5 class="font-weight-normal text-dark mt-4">
                      {{ $t('member.giftVoucher') }}
                    </h5>
                  </CCardBody>
                </router-link>
              </CCol>
              <CCol md="3" class="text-center mb-2">
                <router-link :to="'/store-credit'" class="underline">
                  <CCardBody style="border-radius:15px" color="white" border-color="greyborder" class="box">
                    <div>
                      <CImg src="../../../img/reward/store-credit.png" width="103px" />
                    </div>

                    <CBadge color="success font-weight-normal">
                      {{ $t('comingSoon') }}
                    </CBadge>

                    <h5 class="font-weight-normal text-dark">{{ $t('member.storeCredit') }}</h5>
                  </CCardBody>
                </router-link>
              </CCol>
            </CRow>
            <br />
            <CRow class="d-flex justify-content-center ml-2 mr-2 mb-4">
              <CCol md="3" class="text-center mb-2">
                <router-link :to="'/prepaid-items'" class="underline">
                  <CCardBody style="border-radius:15px" color="white" border-color="greyborder" class="box">
                    <div>
                      <CImg src="../../../img/reward/prepaid-item.png" width="103px" />
                    </div>

                    <CBadge color="success">
                      {{ $t('comingSoon') }}
                    </CBadge>

                    <h5 class="font-weight-normal text-dark">{{ $t('member.deal') }}</h5>
                  </CCardBody>
                </router-link>
              </CCol>
              <CCol md="3" class="text-center mb-2">
                <router-link :to="'/referral'" class="underline">
                  <CCardBody style="border-radius:15px" color="white" border-color="greyborder" class="box">
                    <div>
                      <CImg src="../../../img/reward/referral.png" width="103px" />
                    </div>

                    <CBadge color="success">
                      {{ $t('comingSoon') }}
                    </CBadge>

                    <h5 class="font-weight-normal text-dark">{{ $t('member.referral') }}</h5>
                  </CCardBody>
                </router-link>
              </CCol>
              <CCol md="3" class="text-center mb-2">
                <router-link :to="'/transaction/history'" class="underline">
                  <CCardBody style="border-radius:15px" color="white" border-color="greyborder" class="box">
                    <CImg src="../../../img/reward/transaction.png" width="100px" />

                    <h5 class="font-weight-normal text-dark mt-4">
                      {{ $t('transection') }}
                    </h5>
                  </CCardBody>
                </router-link>
              </CCol>
              <CCol md="3" class="text-center mb-2">
                <router-link :to="'/crm/settings'" class="underline">
                  <CCardBody style="border-radius:15px" color="white" border-color="greyborder" class="box">
                    <CImg src="../../../img/reward/setting.png" width="100px" />

                    <h5 class="font-weight-normal text-dark mt-4">{{ $t('setting') }}</h5>
                  </CCardBody>
                </router-link>
              </CCol>
            </CRow>
            <br />
          </CCardBody>
        </CCard>
      </CCol>
      <CRow>
        <CModal v-if="isRegister === false" :show.sync="popupModal" :footer="footer" class="text-center" centered>
          <h6>
            {{ $t('incaseHasLineOA') }}
            <a href="/reward/register/" class="text-dark">
              <strong> {{ $t('here') }} </strong>
            </a>
          </h6>
          <p>
            {{ $t('seeManual') }}
            <a href="/manual" class="text-dark">
              {{ $t('systemUserManual') }}
            </a>
          </p>
          <template #footer>
            <CRow class="justify-content-center col-md-12"></CRow>
          </template>
        </CModal>
      </CRow>
    </CRow>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoShop from '../containers/NoShop'

export default {
  components: {
    NoShop,
  },
  data() {
    return {
      popupModal: true,
      footer: '',
      isRegister: '',
    }
  },
  computed: {
    ...mapGetters(['shops']),
    checkRegister() {
      if (this.shops.length !== 0) {
        if (
          this.shops[0].isLoyaltyEnabled === undefined ||
          this.shops[0].isLoyaltyEnabled === null
        ) {
          this.isRegister = false
        } else {
          this.isRegister = true
        }
      }
    },
  },
}
</script>

<style scoped>
.underline {
  text-decoration: none;
}

.box:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.box {
  height: 100%;
}
</style>
